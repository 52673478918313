import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import WelcomePage from './pages/WelcomePage.js';
import MiddlePage from './pages/MiddlePage.js';
import RegisterPage from './pages/RegisterPage.js';
import UploadPage from './pages/UploadPage2.js';
import ProcessingPage from './pages/ProcessingPage.js';
import FormPage from './pages/FormPage2.js';
import PersonalPage from './pages/PersonalPage2.js';
import ConfirmPage from './pages/ConfirmPage2.js';
import OTPPage from './pages/OTPPage.js';
import ThanksPage from './pages/ThanksPage.js';
import ToSPage from './pages/ToSPage.js';
import PrivacyPage from './pages/PrivacyPage.js';
import IDUploadPage from './pages/IDUploadPage.js';


function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/middle" element={<MiddlePage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/upload" element={<UploadPage />} />
          <Route path="/processing" element={<ProcessingPage />} />
          <Route path="/form" element={<FormPage />} />
          <Route path="/personal" element={<PersonalPage />} />
          <Route path="/confirm" element={<ConfirmPage />} />
          <Route path="/otp" element={<OTPPage />} />
          <Route path="/upload-id" element={<IDUploadPage />} />
          <Route path="/thanks" element={<ThanksPage />} />
          <Route path="/tos" element={<ToSPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
        </Routes>
      </div>
    </Router>
    
  );
}

export default App;