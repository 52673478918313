import React, { useState, useEffect } from "react";
import ConfirmationModal from "./ConfirmationModal.js";
import "../css/ReceiptForm.css";

const FilePreview = ({ url }) => {
  const isPdf = url?.includes(".pdf");

  if (!url) {
    return <p>No file preview available.</p>;
  }

  return isPdf ? (
    <iframe
      src={url}
      width="100%"
      height="165px"
      style={{ border: "none" }}
      title="PDF Document Preview"
      onError={() => console.error("Failed to load the PDF document.")}
    >
      PDF cannot be displayed
    </iframe>
  ) : (
    <img
      src={url}
      alt="Document Preview"
      style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: "0.3rem" }}
    />
  );
};

function ReceiptForm({
  index,
  formData,
  onChange,
  outOfPocketChange,
  onRemove,
  disableReupload = false,
  validity,
  showValidationError,
  setValidity,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [outOfPocketValue, setOutOfPocketValue] = useState(
    formData.outOfPocket || ""
  );
  const [fileDetails, setFileDetails] = useState({ name: "", size: "" });
  const [paidInFull, setPaidInFull] = useState(formData.paidInFull || "");

  useEffect(() => {
    try {
      const storedFileDetails =
        JSON.parse(sessionStorage.getItem("fileDetails")) || [];
      if (storedFileDetails[index]) {
        setFileDetails({
          name: storedFileDetails[index].name || "No file selected",
          size: storedFileDetails[index].size || "Size not available",
        });
      }
    } catch (error) {
      console.error("Error parsing file details from sessionStorage:", error);
      setFileDetails({
        name: "No file selected",
        size: "Size not available",
      });
    }
  }, [index]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const confirmRemoval = () => {
    onRemove();
    setIsModalOpen(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    onChange(name, value);
  };

  const handleOutOfPocketChangeLocal = (event) => {
    const { value } = event.target;
    setOutOfPocketValue(value);
    outOfPocketChange(index, value);
    if (value.trim() !== "" && /^\$?\d+(\.\d{1,2})?$/.test(value)) {
      setValidity((prevValidity) => {
        const newValidity = [...prevValidity];
        newValidity[index] = { ...newValidity[index], outOfPocket: true };
        return newValidity;
      });
    }
  };

  const handlePaidInFullChange = (event) => {
    const { value } = event.target;
    setPaidInFull(value);
    onChange("paidInFull", value);
    setValidity((prevValidity) => {
      const newValidity = [...prevValidity];
      newValidity[index] = { ...newValidity[index], paidInFull: true };
      return newValidity;
    });
  };

  const getValidityClass = (fieldName) => {
    return showValidationError && validity[index]?.[fieldName] === false
      ? "invalid-field"
      : "";
  };

  return (
    <div className="receipt-former">
      <div className="form-group">
        <label htmlFor={`paidInFull-${index}`} style={{ fontWeight: "normal" }}>
          Did you pay the out of pocket fee in full?
        </label>
        <div className="radio-button-group">
          <input
            type="radio"
            id={`paidInFull-yes-${index}`}
            name={`paidInFull-${index}`}
            value="yes"
            checked={paidInFull === "yes"}
            onChange={handlePaidInFullChange}
            className="sr-only"
          />
          <label
            htmlFor={`paidInFull-yes-${index}`}
            className={`radio-button ${showValidationError && validity[index]?.paidInFull === false ? 'invalid' : ''}`}
          >
            Yes
          </label>
          <input
            type="radio"
            id={`paidInFull-no-${index}`}
            name={`paidInFull-${index}`}
            value="no"
            checked={paidInFull === "no"}
            onChange={handlePaidInFullChange}
            className="sr-only"
          />
          <label
            htmlFor={`paidInFull-no-${index}`}
            className={`radio-button ${showValidationError && validity[index]?.paidInFull === false ? 'invalid' : ''}`}
          >
            No
          </label>
        </div>
        {showValidationError && validity[index]?.paidInFull === false && (
          <p className="error-messages">Please select if paid in full.</p>
        )}
      </div>
      <div className={`form-group`}>
        <input
          type="text"
          id={`${index}-outOfPocket`}
          placeholder="Out of Pocket Amount"
          name="outOfPocket"
          value={outOfPocketValue}
          onChange={handleOutOfPocketChangeLocal}
          className={getValidityClass("outOfPocket")}
          required
        />
        <label htmlFor={`${index}-outOfPocket`}>Out of Pocket Amount</label>
        {showValidationError && validity[index]?.outOfPocket === false && (
          <p className="error-messages">Please enter a valid amount.</p>
        )}
      </div>
      <div className={`form-group`}>
        <textarea
          id={`${index}-notes`}
          placeholder="Notes"
          name="notes"
          value={formData.notes}
          onChange={handleInputChange}
        ></textarea>
        <label htmlFor={`${index}-notes`}>Notes</label>
      </div>
      <ConfirmationModal
        open={isModalOpen}
        onConfirm={confirmRemoval}
        onCancel={() => setIsModalOpen(false)}
      />
    </div>
  );
}

export default ReceiptForm;
