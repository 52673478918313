import React, { useState, useEffect } from 'react';
import '../css/StickyHeader.css';

function StickyHeader({ currentStep }) {
  const [shadow, setShadow] = useState(false);

  const handleScroll = () => {
    const header = document.querySelector('.sticky-header');
    const container = document.querySelector('.container');
    if (header) {
      const headerHeight = header.offsetHeight;

      if (window.innerWidth >= 768) {
        // For larger screens, check the scrollTop of the container
        if (container) {
          const scrollTop = container.scrollTop;
          setShadow(scrollTop > headerHeight);
        }
      } else {
        // For smaller screens, check the position of the header relative to the viewport
        const rect = header.getBoundingClientRect();
        setShadow(rect.top <= 0);
      }
    }
  };

  useEffect(() => {
    const container = document.querySelector('.container');

    const handleResize = () => {
      if (window.innerWidth >= 768) {
        if (container) {
          container.addEventListener('scroll', handleScroll);
          window.removeEventListener('scroll', handleScroll);
        }
      } else {
        window.addEventListener('scroll', handleScroll);
        if (container) {
          container.removeEventListener('scroll', handleScroll);
        }
      }
      handleScroll(); // Initial check
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={`sticky-header ${shadow ? 'shadow' : ''}`}>
      Step {currentStep} / 5
    </div>
  );
}

export default StickyHeader;
