import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { ReactComponent as BalloonIcon } from "../assets/images/BalloonIcon.svg";
import "../css/ThankYouPage.css";
import confetti from 'canvas-confetti';

const ThanksPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Add the celebrating class to the body
    document.body.classList.add('is-celebrating');

    confetti({
      particleCount: 150,
      spread: 70,
      origin: { y: 0.3 } // Makes confetti come from above
    });

    // Clean up by removing the class after the confetti animation
    const timeout = setTimeout(() => {
      document.body.classList.remove('is-celebrating');
    }, 5000); // assuming the confetti animation lasts 5000 ms

    return () => clearTimeout(timeout);
  }, []); // Empty dependency array means this effect runs only once after the initial render

  return (
    <div className="centered-content">
      <BalloonIcon />
      <h1>That's all!</h1>
      <p>
        You'll receive a notification via email once your claim <br /> has been
        approved by one of our team members.
      </p>
      <button
        className="small-button"
        onClick={() => navigate("/upload", { state: { from: "thanks" } })}
      >
        Submit another claim
      </button>
    </div>
  );
};

export default ThanksPage;
