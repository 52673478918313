import React from 'react';

const TermsContent = () => {
  return (
    <div className='terms-content'>
      <h3>1. About the Website</h3>
      <p>Welcome to joinwell.com.au (Website). The Website allows you to access information about Well and use the Well Claim Center (Services).</p>
      <p>The Website is operated by Well, ACN/ABN 1234567890. Access to and use of the Website, or any of its associated products or Services, is provided by Well. Please read these terms and conditions (Terms) carefully. By using, browsing and/or reading the Website, this signifies that you have read, understood and agree to be bound by the Terms. If you do not agree with the Terms, you must cease usage of the Website, or any of Services, immediately.</p>
      <p>Well reserves the right to review and change any of the Terms by updating this page at its sole discretion. When Well updates the Terms, it will use reasonable endeavours to provide you with notice of updates to the Terms. Any changes to the Terms take immediate effect from the date of their publication. Before you continue, we recommend you keep a copy of the Terms for your records.</p>
      
      <h3>2. Acceptance of the Terms</h3>
      <p>You accept the Terms by remaining on the Website. Where the option is available in the user interface, you may also accept the Terms by clicking to accept or agree to the Terms.</p>
      
      <h3>3. About the Service</h3>
      <p>Well Claim Center is a screening and claim submission tool for anyone seeking Medlink funding. Well is Medlink’s triage and service delivery partner. To learn more about Medlink please visit their website medlink.org.au and also be sure to read and accept their terms and conditions and privacy policy.</p>
      <p>You acknowledge and agree that any accounts presently offered, as well as the features of the Well product or experience may change from time to time, and may be governed by separate terms which apply specific to the account. Where special account-specific terms apply, you will be informed, and must accept those terms before you are able to use such an account. For the avoidance of doubt these Terms apply unless otherwise agreed or amended by account-specific terms.</p>
      <p>Some accounts may be governed by a separate Software Licensing Agreement with Well, which may amend the terms of use. For the avoidance of doubt these Terms apply unless otherwise agreed or amended by the terms of an applicable Software Licensing Agreement.</p>
      
      <h3>4. Acceptable use of the Service</h3>
      <p>Well Claim Center, its related features, and website must only be used lawfully. Well reserves the right to suspend, cancel, or otherwise deny access to users and accounts who use the service:</p>
      <ul>
        <li>To engage in any act that would disrupt the access, availability, and security of Well Claim Center and other Well services, including but not limited to:
          <ul>
            <li>Tampering with, reverse-engineering, or hacking our infrastructure.</li>
            <li>Modifying, disabling, or compromising the performance of the Well Claim Center or other Well services.</li>
            <li>Overwhelming, or attempting to overwhelm our infrastructure by imposing an unreasonably large load on our systems that consume extraordinary resources.</li>
            <li>Compromising the integrity of our system, including probing, scanning and testing the vulnerability of our system unless expressly permitted by Well.</li>
          </ul>
        </li>
        <li>For any illegal purpose, or to violate any laws, including and without limitation to data, privacy, and export control laws.</li>
        <li>To stalk, harass or threaten users and any member of the public.</li>
        <li>To misrepresent or defraud any user or member of the public through phishing, spoofing, manipulating headers or other identifiers, impersonating anyone else, or falsely implying any sponsorship or association with Well or any third party.</li>
        <li>To access or search any part of the Service, or any other Service owned by Well other than our publicly supported interface, or otherwise allowed for in an applicable Software Licensing Agreement.</li>
        <li>To post, upload, share, or otherwise circulate content in violation of Well Claim Center’s content policy.</li>
      </ul>
      
      <h3>5. Security and Data Privacy</h3>
      <p>Well takes your privacy seriously and information provided through your use of the Website and/or Services are subject to Well’s Privacy Policy, which is available on the Website. The Privacy Policy also addresses Well’s processes, policies, and obligations in respect of Well Claim Center security breaches.</p>
      
      <h3>6. Data Use</h3>
      <p>Well collects, stores, and processes your data through its website and the Well Claim Center. The data is used to provide Services to you, as well as to facilitate Well’s business operations. The Privacy Policy outlined how your data is collected, stored, and processed by Well. The Privacy Policy also addresses Well's processes, policies, and obligations in respect of data encryption and removal requests.</p>
      
      <h3>7. Subscription to use the Service</h3>
      <p>In order to access the Services, you must register your details with Well through the Well Claim Center. If and when subscriptions become available, you may also choose to purchase a subscription through the Website (Subscription) and pay the applicable fee for the selected Subscription (Subscription Fee). After initial registration or purchasing a Subscription, you will be considered a member (‘Member’).</p>
      <p>In purchasing the Subscription, you acknowledge and agree that it is your responsibility to ensure that the Subscription you elect to purchase is suitable for your use.</p>
      <p>Before, during or after you submit a claim or have purchased the Subscription, you will then be required to provide registration details through the Website before you can access the Services (Account).</p>
      <p>As part of the registration process, or as part of your continued use of the Services, you may be required to provide personal information about yourself (such as identification or contact details), including but not limited to name, email address, medical information, invoices and receipts.</p>
      <p>You warrant that any information you give to Well in the course of completing the registration process will always be accurate, correct and up to date.</p>
      <p>Once you have completed the registration or claim submission process, you will be a registered user of the Website and agree to be bound by the Terms ("User"). As a Member you will be granted immediate access to the Services from the time you have completed the registration process until the subscription period expires (Subscription Period).</p>
      <p>You may not use the Services and may not accept the Terms if:</p>
      <ul>
        <li>you are not of legal age to form a binding contract with Well; or</li>
        <li>you are a person barred from receiving the Services under the laws of Australia or other countries including the country in which you are resident or from which you use the Services.</li>
      </ul>
      
      <h3>8. Payments</h3>
      <p>Subject to the terms of any applicable Software License Agreement, any Subscription Fees may be paid by all payment methods available on the Website, and may change from time to time.</p>
      <p>Payments made or received in the course of your use of Well Claim Center may be made using third-party applications and services not owned, operated, or otherwise controlled by Well. You acknowledge and agree that Well will not be liable for any losses or damage arising from the operations of third-party payment applications and services. You further acknowledge and warrant that you have read, understood and agree to be bound by the terms and conditions of the applicable third-party payment applications and services you choose to use as a payment method for Well Claim Center services.</p>
      <p>You acknowledge and agree that where a request for the payment of the Subscription Fee is returned or denied, for whatever reason, by your financial institution or is unpaid by you for any other reason, then you are liable for any costs, including banking fees and charges, associated with the Subscription Fee.</p>
      <p>You agree and acknowledge that Well can vary the Subscription Fee at any time and that the varied Subscription Fee will come into effect following the conclusion of the existing Subscription.</p>
      
      <h3>9. Refund Policy</h3>
      <p>Well offers refunds in accordance with the Australian Consumer Law and on the terms set out in these terms and conditions. Any benefits set out in these terms and conditions may apply in addition to consumer's rights under the Australian Consumer Law.</p>
      <p>Well will only provide you with a refund of the Subscription Fee in the event they are unable to continue to provide the Services or if the manager of Well makes a decision, at its absolute discretion, that it is reasonable to do so under the circumstances. Where this occurs, the refund will be in the proportional amount of the Subscription Fee that remains unused by the Member (Refund).</p>
      
      <h3>10. Copyright and Intellectual Property</h3>
      <p>The Website, the Services and all of the related products of Well are subject to copyright. The material on the Website is protected by copyright under the laws of Australia and through international treaties. Unless otherwise indicated, all rights (including copyright) in the Services and compilation of the Website (including but not limited to text, graphics, logos, button icons, video images, audio clips, Website code, scripts, design elements and interactive features) or the Services are owned or controlled for these purposes, and are reserved by Well or its contributors.</p>
      <p>All trademarks, service marks and trade names are owned, registered and/or licensed by Well, who grants to you a worldwide, non-exclusive, royalty-free, revocable license whilst you are a User to:</p>
      <ul>
        <li>use the Website pursuant to the Terms;</li>
        <li>copy and store the Website and the material contained in the Website in your device's cache memory; and</li>
        <li>print pages from the Website for your own personal and non-commercial use.</li>
      </ul>
      <p>Well does not grant you any other rights whatsoever in relation to the Website or the Services. All other rights are expressly reserved by Well.</p>
      <p>Well retains all rights, title and interest in and to the Website and all related Services. Nothing you do on or in relation to the Website will transfer any:</p>
      <ul>
        <li>business name, trading name, domain name, trade mark, industrial design, patent, registered design or copyright, or</li>
        <li>a right to use or exploit a business name, trading name, domain name, trade mark or industrial design, or</li>
        <li>a thing, system or process that is the subject of a patent, registered design or copyright (or an adaptation or modification of such a thing, system or process), to you.</li>
      </ul>
      <p>You may not, without the prior written permission of Well and the permission of any other relevant rights owners: broadcast, republish, upload to a third party, transmit, post, distribute, show or play in public, adapt or change in any way the Services or third party Services for any purpose, unless otherwise provided by these Terms. This prohibition does not extend to materials on the Website, which are freely available for re-use or are in the public domain.</p>
      
      <h3>11. General Disclaimer</h3>
      <p>(a) Nothing in the Terms limits or excludes any guarantees, warranties, representations or conditions implied or imposed by law, including the Australian Consumer Law (or any liability under them) which by law may not be limited or excluded.</p>
      <p>(b) Subject to this clause and to the extent permitted by law:</p>
      <ul>
        <li>(i) all terms, guarantees, warranties, representations or conditions which are not expressly stated in the Terms are excluded; and</li>
        <li>(ii) Well will not be liable for any special, indirect or consequential loss or damage (unless such loss or damage is reasonably foreseeable resulting from our failure to meet an applicable Consumer Guarantee), loss of profit or opportunity, or damage to goodwill arising out of or in connection with the Services or these Terms (including as a result of not being able to use the Services or the late supply of the Services), whether at common law, under contract, tort (including negligence), in equity, pursuant to statute or otherwise.</li>
      </ul>
      <p>(c) Use of the Website and the Services is at your own risk. Everything on the Website and the Services is provided to you "as is" and "as available" without warranty or condition of any kind. None of the affiliates, directors, officers, employees, agents, contributors and licensors of Well make any express or implied representation or warranty about the Services or any products or Services (including the products or Services of Well) referred to on the Website. This includes (but is not restricted to) loss or damage you might suffer as a result of any of the following:</p>
      <ul>
        <li>(i) failure of performance, error, omission, interruption, deletion, defect, failure to correct defects, delay in operation or transmission, computer virus or other harmful component, loss of data, communication line failure, unlawful third party conduct, or theft, destruction, alteration or unauthorised access to records;</li>
        <li>(ii) the accuracy, suitability or currency of any information on the Website, the Services, or any of its Services related products (including third party material and advertisements on the Website);</li>
        <li>(iii) costs incurred as a result of you using the Website, the Services or any of the products of Well; and</li>
        <li>(iv) the Services or operation in respect to links which are provided for your convenience.</li>
      </ul>
      
      <h3>12. Limitation of Liability</h3>
      <p>(a) Well's total liability arising out of or in connection with the Services or these Terms, however arising, including under contract, tort (including negligence), in equity, under statute or otherwise, will not exceed the resupply of the Services to you.</p>
      <p>(b) You expressly understand and agree that Well, its affiliates, employees, agents, contributors and licensors shall not be liable to you for any direct, indirect, incidental, special consequential or exemplary damages which may be incurred by you, however caused and under any theory of liability. This shall include, but is not limited to, any loss of profit (whether incurred directly or indirectly), any loss of goodwill or business reputation and any other intangible loss.</p>
      
      <h3>13. Termination of Contract</h3>
      <p>(a) The Terms will continue to apply until terminated by either you or by Well as set out below.</p>
      <p>(b) If you want to terminate the Terms, you may do so by:</p>
      <ul>
        <li>(i) not renewing the Subscription prior to the end of the Subscription Period;</li>
        <li>(ii) providing Well with 14 days’ notice of your intention to terminate; and</li>
        <li>(iii) closing your accounts for all of the services which you use, where Well has made this option available to you.</li>
      </ul>
      <p>(c) Any notices pursuant to Clause 13.2 above should be sent, in writing, to Well via the 'Contact Us' link on our homepage.</p>
      <p>(d) Well may at any time, terminate the Terms with you if:</p>
      <ul>
        <li>(i) you do not renew the Subscription at the end of the Subscription Period;</li>
        <li>(ii) you have breached any provision of the Terms or intend to breach any provision;</li>
        <li>(iii) Well is required to do so by law;</li>
        <li>(iv) the provision of the Services to you by Well is, in the opinion of Well, no longer commercially viable.</li>
      </ul>
      <p>(e) Subject to local applicable laws, Well reserves the right to discontinue or cancel your Subscription or Account at any time and may suspend or deny, in its sole discretion, your access to all or any portion of the Website or the Services without notice if you breach any provision of the Terms or any applicable law or if your conduct impacts Well's name or reputation or violates the rights of those of another party.</p>
      
      <h3>14. Indemnity</h3>
      <p>You agree to indemnify Well, its affiliates, employees, agents, contributors, third party content providers and licensors from and against:</p>
      <ul>
        <li>(a) all actions, suits, claims, demands, liabilities, costs, expenses, loss and damage (including legal fees on a full indemnity basis) incurred, suffered or arising out of or in connection with your content;</li>
        <li>(b) any direct or indirect consequences of you accessing, using or transacting on the Website or attempts to do so; and/or</li>
        <li>(c) any breach of the Terms.</li>
      </ul>
      
      <h3>15. Dispute Resolution</h3>
      <p>15.1. Compulsory:</p>
      <p>If a dispute arises out of or relates to the Terms, either party may not commence any Tribunal or Court proceedings in relation to the dispute, unless the following clauses have been complied with (except where urgent interlocutory relief is sought).</p>
      <p>15.2. Notice:</p>
      <p>A party to the Terms claiming a dispute (Dispute) has arisen under the Terms, must give written notice to the other party detailing the nature of the dispute, the desired outcome and the action required to settle the Dispute.</p>
      <p>15.3. Resolution:</p>
      <ul>
        <li>(a) Within 28 days of the Notice endeavour in good faith to resolve the Dispute expeditiously by negotiation or such other means upon which they may mutually agree;</li>
        <li>(b) If for any reason whatsoever, 28 days after the date of the Notice, the Dispute has not been resolved, the Parties must either agree upon selection of a mediator or request that an appropriate mediator be appointed by the Australian Mediation Association;</li>
        <li>(c) The Parties are equally liable for the fees and reasonable expenses of a mediator and the cost of the venue of the mediation and without limiting the foregoing undertake to pay any amounts requested by the mediator as a precondition to the mediation commencing. The Parties must each pay their own costs associated with the mediation;</li>
        <li>(d) The mediation will be held in Melbourne, Australia.</li>
      </ul>
      <p>15.4. Confidential</p>
      <p>All communications concerning negotiations made by the Parties arising out of and in connection with this dispute resolution clause are confidential and to the extent possible, must be treated as "without prejudice" negotiations for the purpose of applicable laws of evidence.</p>
      <p>15.5. Termination of Mediation:</p>
      <p>If 2 months have elapsed after the start of a mediation of the Dispute and the Dispute has not been resolved, either Party may ask the mediator to terminate the mediation and the mediator must do so.</p>
      
      <h3>16. Venue and Jurisdiction</h3>
      <p>The Services offered by Well is intended to be viewed by residents of Australia. In the event of any dispute arising out of or in relation to the Website, you agree that the exclusive venue for resolving any dispute shall be in the courts of Victoria.</p>
      
      <h3>17. Governing Law</h3>
      <p>The Terms are governed by the laws of Victoria. Any dispute, controversy, proceeding or claim of whatever nature arising out of or in any way relating to the Terms and the rights created hereby shall be governed, interpreted and construed by, under and pursuant to the laws of Victoria, Australia without reference to conflict of law principles, notwithstanding mandatory rules. The validity of this governing law clause is not contested. The Terms shall be binding to the benefit of the parties hereto and their successors and assigns.</p>
      
      <h3>18. Severance</h3>
      <p>If any part of these Terms is found to be void or unenforceable by a Court of competent jurisdiction, that part shall be severed and the rest of the Terms shall remain in force.</p>
    </div>
  );
};

export default TermsContent;
