import React, { useState, useEffect } from "react";
import ProgressBar from "./ProgressBar.js";
import FilePreview from "./FilePreview.js";

const FileUploadItem = ({ file, onRemove }) => {
  const [progress, setProgress] = useState(0);
  const [progressComplete, setProgressComplete] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + 10;
        if (newProgress >= 100) {
          clearInterval(timer);
          setProgressComplete(true);
        }
        return newProgress;
      });
    }, 100);

    return () => clearInterval(timer);
  }, []);

  const fileWithUrl = {
    ...file,
    url: file.url || file.path || "", // Use the appropriate property that holds the file URL or path
  };

  return (
    <div className="file-upload-item">
      <FilePreview
        file={fileWithUrl}
        onRemove={onRemove}
        progressComplete={progressComplete}
      />
      <ProgressBar progress={progress} />
    </div>
  );
};

export default FileUploadItem;
