import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const OTPPage = () => {
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();

  // Function to send OTP
  const sendOTP = async () => {
    const personalInfo = JSON.parse(localStorage.getItem('personalInfo'));
    if (personalInfo && personalInfo.phone) {
      // Assuming personalInfo.phone is already in the correct format
      const formattedPhone = `+61${personalInfo.phone.replace(/\D/g, '').substring(1)}`;
      console.log("Sending OTP to phone:", formattedPhone);
      try {
        const response = await fetch('http://localhost:4000/send-otp', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ phone: formattedPhone }),
        });
        const data = await response.json();
        if (data.success) {
          alert('OTP sent successfully to ' + formattedPhone);
        } else {
          alert('Failed to send the OTP.');
        }
      } catch (error) {
        console.error('Error sending OTP:', error);
        alert('Error sending OTP.');
      }
    }
  };

  const verifyOtp = async () => {
    // Retrieve personal information and formatted claims data from localStorage
    const personalInfo = JSON.parse(localStorage.getItem('personalInfo'));
    const claimsData = JSON.parse(localStorage.getItem('claimsData')); // Ensure this is the correct key for your formatted claims data
    const formattedPhone = `+61${personalInfo.phone.replace(/\D/g, '').substring(1)}`;

    try {
      // Attempt OTP verification
      const verifyResponse = await fetch('http://localhost:4000/verify-otp', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ phone: formattedPhone, code: otp }),
      });
      const verifyData = await verifyResponse.json();

      if (verifyResponse.ok && verifyData.success) {
          // Proceed with form submission on successful OTP verification
          const submitResponse = await fetch('http://localhost:4000/send-message', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ personalInfo, claimsData }),
          });

          if (submitResponse.ok) {
              // Since the server operation was successful, directly proceed
              alert('Form submitted successfully!');
              navigate('/thanks');
          } else {
              // Handle HTTP error status codes other than 200
              console.error('Form submission HTTP error', submitResponse.status);
              alert('Failed to submit form due to a server error.');
          }
      } else {
          alert('OTP Verification Failed: ' + verifyData.message);
      }
  } catch (error) {
      console.error('Error:', error);
      alert('An error occurred during OTP verification.');
  }
};

const phone = localStorage.getItem('phoneNumberForOTP') || ''; // Fallback to an empty string if not found


  return (
      <div className="main-content">
        <div className="container">
          <div className='intro-container'>
          <h1>Enter OTP</h1>
          <p>Send a One Time Passcode to {phone}</p>
          </div>
          <input type="text" placeholder="OTP Code" value={otp} onChange={(e) => setOtp(e.target.value)} />
          <div>
            <button onClick={verifyOtp}>Verify OTP</button>
            <button onClick={sendOTP}>Send OTP</button>
          </div>
        </div>
      </div>
  );
};

export default OTPPage;
