import React, { useState, useCallback, useEffect, useRef } from 'react';
import axios from 'axios';
import Compressor from "compressorjs";
import { useNavigate } from 'react-router-dom';
import NavButtons from '../components/NavButtons.js';
import UploadDropzone from '../components/UploadDropzone.js';
import FileUploadItem from '../components/FileUploadItem.js';
import ConfirmationModal from '../components/ConfirmationModal.js';
import { ReactComponent as IDPageIcon } from '../assets/images/IDPageIcon.svg';
import StickyHeader from '../components/StickyHeader.js';
import "../css/UploadPage.css";
import "../css/animations.css";
import getBaseURL from '../components/getBaseUrl.js';

const IDUploadPage = ({ onNext }) => {
  const [files, setFiles] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorFiles, setErrorFiles] = useState([]);
  const [selectedFileIndex, setSelectedFileIndex] = useState(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [buttonText, setButtonText] = useState("Skip");
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const handleFileUpload = useCallback((uploadedFiles) => {
    const filePromises = uploadedFiles.map((file) => {
      const fileSize = file.size;
  
      if (!isNaN(fileSize) && fileSize !== undefined) {
        const formattedSize = `${(fileSize / 1024).toFixed(2)} KB`;
  
        if (file.type.startsWith("image/")) {
          return new Promise((resolve, reject) => {
            new Compressor(file, {
              quality: 0.8,
              success: (compressedResult) => {
                const compressedFile = new File([compressedResult], file.name, {
                  type: compressedResult.type,
                  lastModified: Date.now()
                });
                resolve({
                  preview: URL.createObjectURL(compressedFile),
                  compressedFile,
                  name: file.name,
                  size: formattedSize,
                  status: "uploading",
                  progress: 0,
                  error: null,
                  isInvalid: false,
                });
              },
              error: (error) => {
                console.error('Compression Error:', error);
                reject(error);
              },
            });
          });
        } else {
          return Promise.resolve({
            preview: URL.createObjectURL(file),
            compressedFile: file,
            name: file.name,
            size: formattedSize,
            status: "uploading",
            progress: 0,
            error: null,
            isInvalid: false,
          });
        }
      } else {
        console.error('File size is not accessible or invalid:', file);
        return Promise.reject('Invalid file size');
      }
    });
  
    Promise.all(filePromises).then((filesWithPreviews) => {
      setFiles((prevFiles) => {
        const newFiles = [...prevFiles, ...filesWithPreviews];
        const allFileDetails = newFiles.map(file => ({
          name: file.name,
          size: file.size,
          preview: file.preview,
          isInvalid: false,
        }));
        sessionStorage.setItem("fileDetails", JSON.stringify(allFileDetails));
        setButtonText("Continue"); // Update button text to "Continue"
        return newFiles;
      });
      setErrorFiles([]);
    }).catch(error => {
      console.error("Error processing files:", error);
      setErrorFiles([{ fileName: null, errorMessage: "Error processing files." }]);
    });
  }, []);

  const handleRemoveFile = (index) => {
    setSelectedFileIndex(index);
    setIsConfirmationOpen(true);
  };

  const handleConfirmRemove = () => {
    const updatedFiles = files.filter((_, i) => i !== selectedFileIndex);
    setFiles(updatedFiles);
    const updatedFileDetails = updatedFiles.map(file => ({
      name: file.name,
      size: file.size,
      preview: file.preview,
      isInvalid: file.isInvalid,
    }));
    sessionStorage.setItem("fileDetails", JSON.stringify(updatedFileDetails));
    setIsConfirmationOpen(false);
    setSelectedFileIndex(null);
    if (updatedFiles.length === 0) {
      setButtonText("Skip"); // Revert button text to "Skip" if no files are uploaded
    }
  };

  const handleCancelRemove = () => {
    setIsConfirmationOpen(false);
    setSelectedFileIndex(null);
  };

  const handleNext = async () => {
    if (files.length > 0) {
      setIsProcessing(true);
  
      try {
        const formData = new FormData();
        files.forEach((file) => {
          formData.append("files", file.compressedFile);
        });
  
        const response = await axios.post(`${getBaseURL()}/upload2`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
  
        const { successResponses, message } = response.data;
  
        if (successResponses.length > 0) {
          const updatedFiles = files.map((file, index) => {
            const response = successResponses.find(res => res.fileName === file.name);
            return response ? { ...file, imageUrl: response.s3Url, s3Key: response.s3Key, status: "uploaded" } : file;
          });
  
          setFiles(updatedFiles);
          console.log('Updated Files:', updatedFiles); // Log the updated files
  
          // Store the URLs and keys in sessionStorage as an array
          const fileUrls = updatedFiles.map(file => ({ url: file.imageUrl, key: file.s3Key }));
          sessionStorage.setItem('idFileUrls', JSON.stringify(fileUrls));
  
          navigate("/confirm", { state: { uploadedData: updatedFiles } });
        } else {
          setErrorFiles([{ fileName: null, errorMessage: message }]);
        }
      } catch (error) {
        console.error("Error uploading files:", error);
        setErrorFiles([{ fileName: null, errorMessage: "Error uploading files. Please try again." }]);
      } finally {
        setIsProcessing(false);
      }
    } else {
      // Ensure old data isn't sent
      sessionStorage.removeItem("idFileUrls");
      navigate("/confirm");
    }
  };
  

  const handleFileSelect = (event) => {
    const selectedFiles = Array.from(event.target.files);
    handleFileUpload(selectedFiles);
  };

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <div className="main-content">
      <div className="container">
        <StickyHeader currentStep={4} />
        <div className="content-container">
          <IDPageIcon />
          <h2>ID Verification - Optional</h2>
          <p>Please make sure the picture is taken in a well-lit space, and all details are visible.</p>
          <UploadDropzone
            onFileUpload={handleFileUpload}
            fileInputRef={fileInputRef}
            files={files}
            allowMultiple={true} // Allow multiple files
            style={{
              padding: '20px 0',
              marginBottom: '0',
            }}
            />
            <p className="additional-info">Acceptable pieces of ID include: Australian Passport, Foreign Passport (with a valid visa or entry stamp), or Drivers License.</p>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileSelect}
            multiple // Allow multiple files
          />
          <div className="upload-section">
            <div className="preview-section">
              {files.map((file, index) => {
                const hasError = errorFiles.some(errorFile => errorFile.fileName === file.name);
                return (
                  <div key={index}>
                    <FileUploadItem
                      file={file}
                      onRemove={() => handleRemoveFile(index)}
                      isInvalid={hasError}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <ConfirmationModal
            open={isConfirmationOpen}
            onConfirm={handleConfirmRemove}
            onCancel={handleCancelRemove}
          />
        </div>
        <NavButtons
          nextButtonText={buttonText}
          onNextClick={handleNext}
          isNextDisabled={false} // Set to false to ensure the button is always enabled
          isProcessing={isProcessing}
          processingText="Processing..."
          buttonClass={buttonText === "Skip" ? "tertiary-button" : ""}
        />
      </div>
    </div>
  );
};

export default IDUploadPage;
