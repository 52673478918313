import React, { useEffect, useState } from "react";
import UploadProgress from "../components/UploadProgress.js";
import UploadError from "../components/UploadError.js";
import UploadSuccess from "../components/UploadSuccess.js";
import JsonAnimation from "../components/JsonAnimation.js";
import animationData from "../assets/images/process.json";
import "../css/JsonAnimation.css";
import getBaseURL from "../components/getBaseUrl.js";

const statusMessages = [
  "Uploading receipt.",
  "Applying smarts.",
  "Reading.",
  "Finalising.",
];

const ProcessingPage = ({ files, onProcessingComplete, onProcessingError }) => {
  const [statusMessage, setStatusMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const processFiles = async () => {
      let statusIndex = 0;
      setStatusMessage(statusMessages[statusIndex]);
      setProgress(0);

      const intervalId = setInterval(() => {
        statusIndex = (statusIndex + 1) % statusMessages.length;
        setStatusMessage(statusMessages[statusIndex]);
        setProgress((prevProgress) => (prevProgress + 25) % 100);
      }, 2000);

      try {
        const formData = new FormData();
        files.forEach((file, index) =>
          formData.append("files", file.compressedFile, file.name)
        );
        const response = await fetch(`${getBaseURL()}/upload`, {
          method: "POST",
          body: formData,
          credentials: "include",
        });

        if (response.ok) {
          const data = await response.json();

          if (data.errorResponses && data.errorResponses.length > 0) {
            const errorResponsesWithIndex = data.errorResponses.map(
              (errorResponse) => ({
                ...errorResponse,
                index: files.findIndex(
                  (file) => file.name === errorResponse.fileName
                ),
              })
            );
            setError("There was an error processing your file. Please reupload the file");
            onProcessingError(errorResponsesWithIndex);
          } else {
            const extractedData = data.extractedData.map((item) => ({
              ...item,
              imageUrl:
                item.s3Url || `${getBaseURL()}/uploads/${item.fileName}`,
              uniqueKey: `${item.fileName}_${Date.now()}`,
            }));
            console.log("Extracted Data:", extractedData);

            sessionStorage.setItem(
              "uploadedData",
              JSON.stringify(extractedData)
            );

            setSuccess(true);
            setTimeout(() => {
              onProcessingComplete(extractedData);
            }, 2000);
          }
        } else {
          const errorData = await response.json();
          setError(`Failed to upload files: ${errorData.message}`);
          // General error, include all file names
          onProcessingError(
            files.map((file) => ({
              fileName: file.name,
              errorMessage: errorData.message,
            }))
          );
        }
      } catch (error) {
        console.error("Upload error:", error);
        setError("Error uploading files.");
        // General error, include all file names
        onProcessingError(
          files.map((file) => ({
            fileName: file.name,
            errorMessage: "Error uploading files.",
          }))
        );
      } finally {
        clearInterval(intervalId);
      }
    };

    processFiles();
  }, [files, onProcessingComplete, onProcessingError]);

  return (
    <div className="processing-overlay">
      <JsonAnimation
        animationData={animationData}
        statusMessage={statusMessage}
      />
    </div>
  );
};

export default ProcessingPage;
