import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import '../css/ConfirmPage.css';
import { ReactComponent as PersonalIcon } from "../assets/images/Wave.svg";
import NavButtons from '../components/NavButtons.js';
import StickyHeader from '../components/StickyHeader.js';
import ReceiptForm from '../components/ReceiptForm2.js';
import PersonalForm from '../components/PersonalForm.js';
import getBaseURL from '../components/getBaseUrl.js';
import dayjs from 'dayjs';

const ConfirmPage = () => {
  const [personalInfo, setPersonalInfo] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    email: '',
    phone: '',
  });
  const [claimsData, setClaimsData] = useState([]);
  const [personalInfoValidity, setPersonalInfoValidity] = useState({
    firstName: true,
    lastName: true,
    email: true,
    phone: true,
  });
  const [claimsDataValidity, setClaimsDataValidity] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [focus, setFocus] = useState({
    firstName: false,
    lastName: false,
    dateOfBirth: false,
    email: false,
    phone: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const storedPersonalInfo = JSON.parse(sessionStorage.getItem('personalInfo'));
    const storedClaimsData = JSON.parse(sessionStorage.getItem('formPageData'));

    if (storedPersonalInfo) {
      setPersonalInfo(storedPersonalInfo);
      const validity = {};
      Object.keys(storedPersonalInfo).forEach(key => {
        validity[key] = validateField(key, storedPersonalInfo[key]);
      });
      setPersonalInfoValidity(validity);
    }
    if (storedClaimsData) {
      setClaimsData(storedClaimsData);
      const claimsValidity = storedClaimsData.map(claim => {
        const claimValidity = {};
        Object.keys(claim).forEach(key => {
          claimValidity[key] = validateField(key, claim[key]);
        });
        return claimValidity;
      });
      setClaimsDataValidity(claimsValidity);
    }
  }, []);

  const capitalizeWords = string => string.replace(/\b\w/g, char => char.toUpperCase());
  const formatAustralianPhone = phone => phone.replace(/(\d{4})(\d{3})(\d{3})/, '$1-$2-$3');

  const validateField = (name, value) => {
    const decimalRegex = /^(\d{1,3})(,\d{3})*(\.\d{1,3})?$/;

    switch (name) {
      case "firstName":
      case "lastName":
        return value.trim() !== "" && /^[A-Za-z\s]*$/.test(value);
      case "email":
        return value.trim() !== "" && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      case "phone":
        return value.trim() !== "" && /^04\d{8}$/.test(value);
      case 'outOfPocket':
        return decimalRegex.test(value);
      case 'paidInFull':
        return value.trim() !== '';
      default:
        return true;
    }
  };

  const validateAllFields = () => {
    let allValid = true;

    Object.entries(personalInfo).forEach(([key, value]) => {
      if (!validateField(key, value)) {
        console.log(`Validation failed for personal field - ${key}: '${value}'`);
        allValid = false;
      }
    });

    claimsData.forEach((claim, index) => {
      Object.entries(claim).forEach(([key, value]) => {
        if (!validateField(key, value)) {
          console.log(`Validation failed for claim ${index}, field - ${key}: '${value}'`);
          allValid = false;
        }
      });
    });

    return allValid;
  };

  const handleEditChange = (event, key, index = null) => {
    const { value } = event.target;
    const isValid = validateField(key, value);

    if (index !== null) {
      setClaimsData(prev => prev.map((item, idx) => idx === index ? { ...item, [key]: value } : item));
      setClaimsDataValidity(prev => prev.map((item, idx) => idx === index ? { ...item, [key]: isValid } : item));
    } else {
      setPersonalInfo(prev => ({ ...prev, [key]: value }));
      setPersonalInfoValidity(prev => ({ ...prev, [key]: isValid }));
    }
  };

  const handleFormChange = (index, name, value) => {
    const updatedClaims = claimsData.map((claim, idx) =>
      idx === index ? { ...claim, [name]: value } : claim
    );
    setClaimsData(updatedClaims);
    console.log('Updated Claim Data:', updatedClaims);
  };

  const handleOutOfPocketChange = (index, value) => {
    const updatedClaims = claimsData.map((claim, idx) =>
      idx === index ? { ...claim, outOfPocket: value } : claim
    );
    setClaimsData(updatedClaims);
    console.log('Updated Out of Pocket:', updatedClaims);
  };

  const handleRemoveClaim = (index) => {
    const filteredClaims = claimsData.filter((_, idx) => idx !== index);
    setClaimsData(filteredClaims);
    console.log('Claims after removal:', filteredClaims);
  };

  const submitDetails = async () => {
    if (claimsData.length === 0) {
      console.log("Validation failed: No claims data available.");
      alert("No claims data available. Please ensure you have at least one claim.");
      return;
    }
  
    if (!validateAllFields()) {
      console.log("Validation failed for one or more fields.");
      return;
    }
  
    const validateClaimFields = (claim) => {
      const requiredFields = ['outOfPocket', 'paidInFull'];
      const invalidFields = requiredFields.filter(field => !claim[field] || claim[field].trim() === "");
      return {
        isValid: invalidFields.length === 0,
        invalidFields,
      };
    };
  
    const allClaimsValid = claimsData.every(claim => validateClaimFields(claim).isValid);
  
    if (!allClaimsValid) {
      console.log("Validation failed: One or more claims are missing required fields.");
      claimsData.forEach((claim, index) => {
        const validation = validateClaimFields(claim);
        if (!validation.isValid) {
          console.log(`Validation failed for claim ${index}: Missing fields - ${validation.invalidFields.join(', ')}`);
        }
      });
      alert("One or more claims are missing required fields. Please ensure each claim has an out-of-pocket amount and paid in full status.");
      return;
    }
  
    const requiredPersonalInfoFields = ['firstName', 'lastName', 'email', 'phone'];
    const personalInfoValid = requiredPersonalInfoFields.every(field => personalInfo[field] && personalInfo[field].trim() !== "");
  
    if (!personalInfoValid) {
      console.log("Validation failed: Personal info is missing required fields.");
      alert("Personal information is missing required fields. Please ensure all personal information fields are filled out.");
      return;
    }
  
    setIsSubmitting(true);
  
    const formattedPersonalInfo = {
      firstName: capitalizeWords(personalInfo.firstName),
      lastName: capitalizeWords(personalInfo.lastName),
      phone: formatAustralianPhone(personalInfo.phone),
      email: personalInfo.email,
      dateOfBirth: personalInfo.dateOfBirth,
    };
    
    const formattedClaimsData = claimsData.map(claim => ({
      ...claim,
      provider: capitalizeWords(claim.provider || ''),
      providerLocation: capitalizeWords(claim.providerLocation || ''),
      receiptAmount: parseFloat(claim.receiptAmount).toFixed(2),
    }));
  
    const fileUrls = JSON.parse(sessionStorage.getItem('idFileUrls')) || [];
  
    try {
      const sheetResponse = await fetch(`${getBaseURL()}/send-message2`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ personalInfo: formattedPersonalInfo, claimsData: formattedClaimsData, fileUrls }),
      });
  
      if (sheetResponse.ok) {
        const emailResponse = await fetch(`${getBaseURL()}/send-email`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ personalInfo: formattedPersonalInfo, claimsData: formattedClaimsData }),
        });
  
        if (emailResponse.ok) {
          sessionStorage.removeItem('personalInfo');
          sessionStorage.removeItem('formPageData');
          sessionStorage.removeItem('idFileUrls');
          navigate('/thanks');
        } else {
          const errorResponse = await emailResponse.json();
          console.error('Error in email response:', errorResponse);
          throw new Error('HTTP error', emailResponse.status);
        }
      } else {
        const errorResponse = await sheetResponse.json();
        console.error('Error in sheet response:', errorResponse);
        throw new Error('HTTP error', sheetResponse.status);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred during form submission.');
    }
    setIsSubmitting(false);
  };
  
  

  const handleFocus = (field) => {
    setFocus({ ...focus, [field]: true });
  };

  const handleBlur = (field) => {
    if (!personalInfo[field]) {
      setFocus({ ...focus, [field]: false });
    }
  };

  const handleDateInputClick = () => {
    const dateInput = document.getElementById('dateOfBirth');
    dateInput.type = 'date';
  };

  const handleDateInputBlur = () => {
    const dateInput = document.getElementById('dateOfBirth');
    if (!dateInput.value) {
      dateInput.type = 'text';
    }
  };

  const getValidityClass = (fieldName) => {
    return !personalInfoValidity[fieldName] ? 'invalid-field' : '';
  };

  return (
    <div className="main-content">
      <div className="container right-animation-container">
        <StickyHeader currentStep={5} />
        <div className='content-container'>
          <PersonalIcon />
          <div className='confirm-display'>
            <div className='intro-container'>
              <h1>Let's Confirm Everything</h1>
              <p>Take a quick look before we lock in the claim</p>
            </div>
            <div className='confirm-display'>
              <h2>Claim Details</h2>
              {claimsData.map((claim, index) => (
                <div className="receipt-form" key={index}>
                  <ReceiptForm
                    index={index}
                    formData={claim}
                    onChange={(name, value) => handleFormChange(index, name, value)}
                    outOfPocketChange={handleOutOfPocketChange}
                    onRemove={() => handleRemoveClaim(index)}
                    validity={claimsDataValidity}
                    setValidity={setClaimsDataValidity}
                    showValidationError={false}
                    readOnly={false}
                    disableReupload={true}
                  />
                </div>
              ))}
            </div>
            <h2>Personal Information</h2>
            <div className='personal-details-container'>
              <PersonalForm
                formData={personalInfo}
                setFormData={setPersonalInfo}
                fieldValidity={personalInfoValidity}
                setFieldValidity={setPersonalInfoValidity}
                handleFocus={handleFocus}
                handleBlur={handleBlur}
                handleSubmit={submitDetails}
                showValidationError={false}
                handleDateInputClick={handleDateInputClick}
                handleDateInputBlur={handleDateInputBlur}
                getValidityClass={getValidityClass}
              />
            </div>
          </div>
        </div>
        <NavButtons
          nextButtonText="Submit"
          isNextDisabled={isSubmitting}
          onNextClick={submitDetails}
          isProcessing={isSubmitting}
        />
      </div>
    </div>
  );
}

export default ConfirmPage;
