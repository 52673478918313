import React, { useState, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import "./UploadDropzone.css";
import { ReactComponent as ErrorIcon } from "../assets/images/ErrorIcon.svg";
import { ReactComponent as InfoIcon } from "../assets/images/InfoIcon.svg";

const UploadDropzone = ({ onFileUpload, fileInputRef, files, tooltipText, tooltipClassName, allowMultiple, style }) => {
    const [uploadStatus, setUploadStatus] = useState("initial");
    const [isFileBrowserOpen, setFileBrowserOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const dropzoneRef = useRef(null);
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const isMobileDevice = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    };

    useEffect(() => {
        setIsTouchDevice(isMobileDevice());
    }, []);

    useEffect(() => {
        if (uploadStatus === "success" || uploadStatus === "error") {
            const timer = setTimeout(() => {
                setUploadStatus("initial");
                setErrorMessage("");
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [uploadStatus]);

    const onDrop = (acceptedFiles, rejectedFiles) => {
        if (!allowMultiple && files.length > 0) {
            setUploadStatus("error");
            setErrorMessage("Only one file can be uploaded at a time");
            return;
        }
    
        const validFiles = acceptedFiles.filter(file => {
            const validTypes = ["application/pdf", "image/jpeg", "image/png"];
            const extension = file.name.split(".").pop().toLowerCase();
            return (
                validTypes.includes(file.type) &&
                ["pdf", "jpeg", "jpg", "png"].includes(extension)
            );
        });
    
        if (validFiles.length > 0) {
            onFileUpload(validFiles);
            setUploadStatus("success");
        } else {
            setUploadStatus("error");
            setErrorMessage("Invalid Image Detected");
        }
        setFileBrowserOpen(false);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: allowMultiple,
        accept: {
            "application/pdf": [".pdf"],
            "image/jpeg": [".jpeg", ".jpg"],
            "image/png": [".png"],
        },
        noClick: files.length > 0 && !allowMultiple,
        noDrag: files.length > 0 && !allowMultiple,
    });

    useEffect(() => {
        if (isFileBrowserOpen) {
            if (isMobileDevice()) {
                setTimeout(() => {
                    setFileBrowserOpen(false);
                }, 1000);
            }

            const onWindowFocus = () => {
                setFileBrowserOpen(false);
                window.removeEventListener('focus', onWindowFocus);
            };

            window.addEventListener('focus', onWindowFocus);
        }
    }, [isFileBrowserOpen]);

    const handleClick = () => {
        if (files.length === 0 || allowMultiple) {
            setFileBrowserOpen(true);
            if (dropzoneRef.current) {
                dropzoneRef.current.querySelector('input').click();
            }
        } else {
            setUploadStatus("error");
            setErrorMessage("Only one file can be uploaded at a time");
        }
    };

    const handleTooltipClick = (e) => {
        if (isTouchDevice) {
            e.stopPropagation();
            setShowTooltip(!showTooltip);
        }
    };

    const renderTooltipContent = () => {
        if (typeof tooltipText === "string") {
            return <p>{tooltipText}</p>;
        }

        if (Array.isArray(tooltipText)) {
            return (
                <div>
                    {tooltipText.map((item, index) =>
                        item.type === "list" ? (
                            <div key={index}>
                                <strong>{item.heading}</strong>
                                <ul>
                                    {item.items.map((listItem, listIndex) => (
                                        <li key={listIndex}>{listItem}</li>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            <p key={index}>{item.text}</p>
                        )
                    )}
                </div>
            );
        }

        return null;
    };

    const dropzoneClasses = `dropzone ${isDragActive || isFileBrowserOpen ? "dragging" : ""} ${
        uploadStatus === "success"
            ? "dropzone--success"
            : uploadStatus === "error"
            ? "dropzone--error"
            : "dropzone-original"
    }`;

    return (
        <div>
            <div
                {...getRootProps()}
                className={dropzoneClasses}
                ref={dropzoneRef}
                onClick={handleClick}
                style={style} // Apply the inline styles here
            >
                <input {...getInputProps()} style={{ display: "none" }} />
                <div className={`dropzone-content ${tooltipText ? 'with-tooltip' : 'no-tooltip'}`}>
                    <p>
                        Drag your file here or{" "}
                        <span className="browse-files">
                            Browse files
                        </span>
                    </p>
                    <p style={{ fontSize: '12px' }}>Max 1.5mb file size</p>
                    {tooltipText && (
                        <div className="tooltip">
                            <InfoIcon className="info-icon" onClick={handleTooltipClick} />
                            <span className={`tooltiptext ${tooltipClassName} ${isTouchDevice && showTooltip ? 'visible' : ''}`}>
                                {renderTooltipContent()}
                            </span>
                        </div>
                    )}
                </div>
            </div>
            {uploadStatus === "error" && (
                <div className="alert-error">
                    <ErrorIcon size={24} style={{ marginRight: "10px" }} />
                    <p>{errorMessage}</p>
                </div>
            )}
        </div>
    );
};

export default UploadDropzone;
