import React, { forwardRef, useEffect, useState } from "react";
import "./FilePreview.css";
import { FaExclamationTriangle } from "react-icons/fa";
import { ReactComponent as TrashIcon } from "../assets/images/TrashIcon.svg";
import { ReactComponent as TickIcon } from "../assets/images/TickIcon.svg";

const FilePreview = forwardRef(
  ({ file, onRemove, progressComplete, isInvalid }, ref) => {
    const [showCross, setShowCross] = useState(false);
    const [showFilePreview, setShowFilePreview] = useState(false);
    const [showTick, setShowTick] = useState(false);
    const [successClassVisible, setSuccessClassVisible] = useState(false);

    useEffect(() => {
      if (progressComplete) {
        // Show the tick icon
        setShowTick(true);
        // Show the success class
        setSuccessClassVisible(true);
        // Hide the success class after 2-3 seconds
        setTimeout(() => {
          setSuccessClassVisible(false);
        }, 2000);
        // Delay showing the cross icon by 1 second
        setTimeout(() => {
          setShowCross(true);
        }, 1000);
        // Delay showing the file preview by 3 seconds
        setTimeout(() => {
          setShowFilePreview(true);
        }, 3000);
      }
    }, [progressComplete]);

    const getStatusIcon = () => {
      if (file?.status === "success") {
        return <TickIcon />;
      } else if (file?.status === "error") {
        return <FaExclamationTriangle color="red" />;
      } else {
        return null;
      }
    };

    const getFileItemClass = () => {
      if (isInvalid) {
        return "file-item file-item-error";
      } else if (successClassVisible) {
        return "file-item file-item-success";
      } else {
        return "file-item";
      }
    };

    const renderFilePreview = () => {
      if (!file?.preview) {
        return <p>No file preview available.</p>;
      }

      const isPdf = file.name?.toLowerCase().endsWith(".pdf");

      return isPdf ? (
        <iframe
          src={file.preview}
          width="100%"
          height="60px" /* Set a fixed height for the iframe */
          style={{ border: "none" }}
          title="PDF Document Preview"
          onError={() => console.error("Failed to load the PDF document.")}
        >
          PDF cannot be displayed
        </iframe>
      ) : (
        <img
          src={file.preview}
          alt="Document Preview"
          style={{
            width: "30px",
            height: "30px",
            borderRadius: "0.3rem",
          }} /* Set fixed small dimensions for the image */
        />
      );
    };

    return (
      <div ref={ref} className={getFileItemClass()}>
        <div className="file-details">
          <div className="file-info">
            <div className="file-status">
              {showFilePreview ? (
                renderFilePreview()
              ) : showTick ? (
                <TickIcon />
              ) : null}
            </div>
            <div className="file-name">{file?.name}</div>
            <div className="file-icon">{getStatusIcon()}</div>
          </div>
          {showCross && (
            <div className="file-action" onClick={() => onRemove(file)}>
              <TrashIcon />
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default FilePreview;
