import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/ToSPage.css';
import { ReactComponent as Contract } from "../assets/images/Contract.svg";
import { ReactComponent as PrevIcon } from "../assets/images/Chevron.svg";
import PrivacyContent from '../components/PrivacyContent.js';

const PrivacyPage = () => {
    const navigate = useNavigate();

    return (
        <div className='main-content'>
            <div className='container'>
                <div className='content-container'>
                    <Contract />
                    <h1>Privacy Policy</h1>
                    <PrivacyContent />
                    <button className="tos-back-button" onClick={() => navigate('/middle')}>
                        <PrevIcon />
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPage;
