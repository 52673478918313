import React, { useState, useEffect, useRef } from "react";
import ReceiptForm from "../components/ReceiptForm2.js";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import NavButtons from "../components/NavButtons.js";
import StickyHeader from "../components/StickyHeader.js";
import { ReactComponent as FormIcon } from "../assets/images/FormIcon.svg";
import '../css/animations.css';

const FormPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const fileInputRef = useRef(null);
  const [showValidationError, setShowValidationError] = useState(false);
  const [formData, setFormData] = useState([]);
  const [validity, setValidity] = useState([]);

  const outOfPocketPattern = /^\$?\d+(\.\d{1,2})?$/;

  useEffect(() => {
    const stateData = location.state?.uploadedData;
    let uploadedData = stateData || [];

    if (!uploadedData.length) {
      const uploadedDataString = sessionStorage.getItem("fileDetails");
      if (uploadedDataString) {
        uploadedData = JSON.parse(uploadedDataString);
      }
    }

    console.log('Uploaded Data:', uploadedData);

    const processedFormData = uploadedData.map((data) => ({
      imageUrl: data.s3Url,
      name: data.name,
      size: data.size,
      paidInFull: "", // Set to empty string instead of "no"
      outOfPocket: "",
      outOfPocketValid: true,
      notes: "",
      s3Key: data.s3Key,
      s3Url: data.s3Url,
    }));

    console.log('Processed Form Data:', processedFormData);

    if (processedFormData.length > 0) {
      setFormData(processedFormData);
      setValidity(processedFormData.map(() => ({})));
    }
  }, []);

  const handleFormChange = (index, name, value) => {
    const updatedFormData = formData.map((form, i) =>
      i === index ? { ...form, [name]: value } : form
    );
    setFormData(updatedFormData);
    console.log('Updated Form Data:', updatedFormData);
  };

  const handleOutOfPocketChange = (index, value) => {
    const updatedFormData = formData.map((data, i) =>
      i === index ? { ...data, outOfPocket: value } : data
    );
    setFormData(updatedFormData);
    console.log('Updated Form Data (Out of Pocket):', updatedFormData);
  };

  const handleRemoveClaim = (index) => {
    const updatedFormData = formData.filter((_, i) => i !== index);
    setFormData(updatedFormData);

    const updatedValidity = validity.filter((_, i) => i !== index);
    setValidity(updatedValidity);
    console.log('Updated Form Data (After Removal):', updatedFormData);
  };

  const validateFields = () => {
    const updatedValidity = formData.map((data, index) => {
      const isOutOfPocketValid = outOfPocketPattern.test(data.outOfPocket) && data.outOfPocket.trim() !== "";
      const isPaidInFullValid = data.paidInFull.trim() !== "";
      const isS3KeyValid = data.s3Key && data.s3Key.trim() !== "";
      const isS3UrlValid = data.s3Url && data.s3Url.trim() !== "";
      return { outOfPocket: isOutOfPocketValid, paidInFull: isPaidInFullValid, s3Key: isS3KeyValid, s3Url: isS3UrlValid };
    });
    setValidity(updatedValidity);
    console.log('Validity:', updatedValidity);

    return updatedValidity.every(v => v.outOfPocket && v.paidInFull && v.s3Key && v.s3Url);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const isValid = validateFields();

    if (!isValid) {
      setShowValidationError(true);
      return;
    }

    const requiredFields = formData.map((data) => ({
      imageUrl: data.s3Url,
      paidInFull: data.paidInFull,
      outOfPocket: data.outOfPocket,
      notes: data.notes,
      s3Key: data.s3Key,
      s3Url: data.s3Url,
    }));

    console.log('Required Fields:', requiredFields);

    sessionStorage.setItem("formPageData", JSON.stringify(requiredFields));
    navigate("/personal");
  };

  return (
    <div className="main-content">
      <div className="container up-animation-container">
        <StickyHeader currentStep={2} />
        <div className="content-container">
          <FormIcon />
          <h1>Claim Details</h1>
          <p>Help us confirm some additional details from your receipt.</p>
          {formData.map((file, index) => (
            <div className="receipt-form-container" key={index}>
              <ReceiptForm
                index={index}
                formData={file}
                onChange={(name, value) => handleFormChange(index, name, value)}
                outOfPocketChange={handleOutOfPocketChange}
                onRemove={() => handleRemoveClaim(index)}
                imagePreview={file.preview}
                validity={validity}
                showValidationError={showValidationError}
                setValidity={setValidity}
              />
            </div>
          ))}
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            multiple={true}
          />
        </div>
        <NavButtons
          nextButtonText="Continue"
          isNextDisabled={false}
          onNextClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default FormPage;
