// JsonAnimation.js

import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import '../css/JsonAnimation.css';

const JsonAnimation = ({ animationData, statusMessage }) => {
  const animationContainer = useRef(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationData
    });

    // Adding class for styling the SVG directly might be needed depending on Lottie's output
    animationContainer.current.firstChild.classList.add('lottie-svg');

    return () => anim.destroy(); // Clean up the animation instance on component unmount
  }, [animationData]);

  return (
    <div className="animation-container">
      <div ref={animationContainer}></div>
      <div className="caption">{statusMessage}</div>
    </div>
  );
};

export default JsonAnimation;