import React, { useState, useEffect } from 'react';
import '../css/WelcomePage.css';
import '../css/Header.css';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as NextIcon } from '../assets/images/NextIcon.svg';
import { ReactComponent as Logo } from '../assets/images/Logo.svg';


const WelcomePage = () => {
  const navigate = useNavigate();
  const [extraText, setExtraText] = useState('');

  return (
    <div className="app-grid">
      <div className='header'></div>
      <div className="welcome-main-content">
        <div className="welcome-container">
          <Logo />
            <h1>Where Medical Claims Become Simple</h1>
            <p>Streamline your medical claim process with Well. 
              Submit a valid receipt using this app within a month of your appointment. 
              Refer to our <a href='https://www.joinwell.com.au/#FAQ' target='_blank' rel="noopener noreferrer">FAQ</a> for additional support.</p>
          <div className='button-container'>
            <button className='custom-button welcome-button' onClick={() => navigate('/middle')}>
              Submit a Claim
              <NextIcon />
              </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;