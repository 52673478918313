import React from "react";
import { Modal, Button, Box, Typography } from "@mui/material";

const ConfirmationModal = ({ open, onConfirm, onCancel }) => {
  // Define custom styles for the buttons
  const buttonStyles = {
    borderRadius: "2rem",
    border: "1px solid var(--Colour-Form-form-stroke-default, #EAEAEA)",
    width: "140px", // Set a consistent width for the buttons
    height: "40px", // Set a consistent height for the buttons
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };

  const removeButtonStyles = {
    ...buttonStyles,
    background: "#4648E2",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#473794",
    },
    marginLeft: 1.5, // Add margin to the left
  };

  const cancelButtonStyles = {
    ...buttonStyles,
    background: "#FFFFFF",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgba(94, 77, 178, 0.1)",
    },
    marginRight: 1.5, // Add margin to the right
  };

  return (
    <Modal open={open} onClose={onCancel}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%", // Decrease width for mobile
          maxWidth: "400px",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: "24px",
          p: 2,
          outline: "none",
          '@media (min-width: 600px)': {
            p: 4,
          }
        }}
      >
        <Typography variant="h6" gutterBottom>
          Confirm
        </Typography>
        <Typography>Are you sure you want to remove this file?</Typography>
        <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
          <Button
            onClick={onCancel}
            sx={cancelButtonStyles}
          >
            No
          </Button>
          <Button
            onClick={onConfirm}
            variant="contained"
            sx={removeButtonStyles}
          >
            Yes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModal;