import React, { useState } from "react";
import "../css/WelcomePage.css";
import "../css/MiddlePage.css";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Wave } from "../assets/images/Wave.svg";

const MiddlePage = () => {
  const navigate = useNavigate();
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  const handleAgreementChange = (event) => {
    setAgreedToTerms(event.target.checked); // Update state based on checkbox
  };

  return (
    <div className="main-content">
      <div className="container">
        <div className="content-container" style={{marginTop: '50px'}}>
          <Wave />
          <h1>Welcome to Well Claim Center</h1>
          <p>
            If you’re looking for where to submit a claim to Medlink, you’ve
            arrived at the right place. Please review and agree to our Terms
            and Conditions prior to submitting your claim.
          </p>
          <form className="terms-checkbox">
            <input
              type="checkbox"
              name="terms"
              checked={agreedToTerms}
              onChange={handleAgreementChange}
            />
            <label>
              <p>
                I agree with the{" "}
                <a
                  href="https://www.joinwell.com.au/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a
                  href="https://www.joinwell.com.au/privacy-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                </a>
              </p>
            </label>
          </form>
        </div>
        <footer className="fixed-footer">
          <button
            className="custom-button"
            onClick={() => navigate("/upload")}
            disabled={!agreedToTerms}
            style={{
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              width: "95%",
            }}
          >
            Get Started
          </button>
        </footer>
      </div>
    </div>
  );
};

export default MiddlePage;
