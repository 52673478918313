import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NavButtons from "../components/NavButtons.js";
import StickyHeader from "../components/StickyHeader.js";
import PersonalForm from "../components/PersonalForm.js";
import { ReactComponent as PersonalIcon } from "../assets/images/Wave.svg";
import "../css/animations.css";
import "../css/PersonalPage.css";
import dayjs from "dayjs";
import { ReactComponent as ErrorIcons } from "../assets/images/ErrorIcons.svg";

const PersonalPage = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    email: "",
    phone: "",
  });

  const [focus, setFocus] = useState({
    firstName: false,
    lastName: false,
    dateOfBirth: false,
    email: false,
    phone: false,
  });

  const handleFocus = (field) => {
    setFocus({ ...focus, [field]: true });
  };

  const handleBlur = (field) => {
    if (!formData[field]) {
      setFocus({ ...focus, [field]: false });
    }
  };

  const [fieldValidity, setFieldValidity] = useState({
    firstName: true,
    lastName: true,
    dateOfBirth: true,
    email: true,
    phone: true,
  });

  const [showValidationError, setShowValidationError] = useState(false);
  const [scrollTrigger, setScrollTrigger] = useState(0);

  const navigate = useNavigate();

  const validateField = (name, value) => {
    switch (name) {
      case "firstName":
      case "lastName":
        return value.trim() !== "" && /^[A-Za-z\s]*$/.test(value);
      case "email":
        return value.trim() !== "" && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
      case "phone":
        return value.trim() !== "" && /^04\d{8}$/.test(value);
      case "dateOfBirth":
        const parsedDate = dayjs(value, "DD/MM/YYYY");
        if (!parsedDate.isValid()) return false;
        const today = dayjs();
        let age = today.diff(parsedDate, "year");
        return age >= 18;
      default:
        return false;
    }
  };

  useEffect(() => {
    if (showValidationError) {
      setTimeout(() => {
        const firstInvalidField = document.querySelector(".invalid-field");
        if (firstInvalidField) {
          const isDesktop = window.innerWidth >= 768;
          const container = document.querySelector(".container");
  
          if (isDesktop) {
            // Calculate the offset of the first invalid field relative to the container
            const containerRect = container.getBoundingClientRect();
            const invalidFieldRect = firstInvalidField.getBoundingClientRect();
            const offsetTop = invalidFieldRect.top - containerRect.top;
  
            container.scrollTo({
              top: offsetTop - 20, // Adjust the offset as needed
              behavior: "smooth",
            });
          } else {
            firstInvalidField.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }
      }, 100);
    }
  }, [showValidationError, scrollTrigger]);
  

  const handleSubmit = (e) => {
    e.preventDefault();
    const newFieldValidity = {
      firstName: validateField("firstName", formData.firstName),
      lastName: validateField("lastName", formData.lastName),
      dateOfBirth: validateField("dateOfBirth", formData.dateOfBirth),
      email: validateField("email", formData.email),
      phone: validateField("phone", formData.phone),
    };

    setFieldValidity(newFieldValidity);

    const hasInvalidData = Object.values(newFieldValidity).some(
      (isValid) => !isValid
    );
    setShowValidationError(hasInvalidData);

    if (hasInvalidData) {
      setScrollTrigger((prev) => prev + 1);
    } else {
      sessionStorage.setItem("personalInfo", JSON.stringify(formData));
      navigate("/upload-id");
    }
  };

  const handleDateInputClick = () => {
    const dateInput = document.getElementById("dateOfBirth");
    dateInput.type = "date";
  };

  const handleDateInputBlur = () => {
    const dateInput = document.getElementById("dateOfBirth");
    if (!dateInput.value) {
      dateInput.type = "text";
    }
  };

  const getValidityClass = (fieldName) => {
    return !fieldValidity[fieldName] ? "invalid-field" : "";
  };

  return (
    <div className="main-content">
      <div className="container">
        <StickyHeader currentStep={3} />
        <div className="content-container">
          <PersonalIcon />
          <h1>About You</h1>
          <p>We need to confirm your identity and we’ll take it from there.</p>
          <PersonalForm
            formData={formData}
            setFormData={setFormData}
            fieldValidity={fieldValidity}
            setFieldValidity={setFieldValidity}
            handleFocus={handleFocus}
            handleBlur={handleBlur}
            handleSubmit={handleSubmit}
            showValidationError={showValidationError}
            handleDateInputClick={handleDateInputClick}
            handleDateInputBlur={handleDateInputBlur}
            getValidityClass={getValidityClass}
          />
        </div>
        <NavButtons
          nextButtonText="Continue"
          isNextDisabled={false}
          onNextClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default PersonalPage;
