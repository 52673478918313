import NavButtons from '../components/NavButtons.js';

const RegisterPage = () => {
return (
      <div className="main-content">
        <div className="container">
            <h1>Welcome to the Register Page</h1>
            <NavButtons
            nextButtonText="Next"
          />
        </div>
      </div>
  );
};

export default RegisterPage;