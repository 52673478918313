import React, { useState, useEffect } from 'react';
import '../css/animations.css';
import '../css/PersonalPage.css';
import { DatePicker } from "antd";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';

dayjs.extend(customParseFormat);

const isMobileDevice = () => {
    return typeof window.orientation !== "undefined" || navigator.userAgent.indexOf('IEMobile') !== -1;
};

const PersonalForm = ({
    formData,
    setFormData,
    fieldValidity,
    setFieldValidity,
    handleFocus,
    handleBlur,
    handleSubmit,
    showValidationError,
    handleDateInputClick,
    handleDateInputBlur,
    getValidityClass
}) => {
    const [isMobile, setIsMobile] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        setIsMobile(isMobileDevice());
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setFieldValidity({ ...fieldValidity, [name]: true });
    };

    const handleDateChange = (date, dateString) => {
        if (date && date.isValid() && date.isBefore(dayjs().endOf('day'))) {
            setFormData({ ...formData, dateOfBirth: date.format('DD/MM/YYYY') });
            setFieldValidity({ ...fieldValidity, dateOfBirth: true });
        } else {
            setFormData({ ...formData, dateOfBirth: '' });
            setFieldValidity({ ...fieldValidity, dateOfBirth: false });
        }
    };

    const disabledDate = (current) => {
        return current && current > dayjs().endOf('day');
    };

    useEffect(() => {
        if (formData.dateOfBirth) {
            setIsFocused(true);
        } else {
            setIsFocused(false);
        }
    }, [formData.dateOfBirth]);

    return (
        <form id="personalForm" onSubmit={handleSubmit}>
            <div className='form-group'>
                <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    className={`form-control ${getValidityClass('firstName')}`}
                    onFocus={() => handleFocus('firstName')}
                    onBlur={() => handleBlur('firstName')}
                    onChange={handleInputChange}
                    required
                    placeholder="First Name"
                />
                <label htmlFor="firstName">First Name</label>
                {showValidationError && fieldValidity.firstName === false && (
                    <p className="error-messages">Please enter a valid first name.</p>
                )}
            </div>
            <div className='form-group'>
                <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    className={`form-control ${getValidityClass('lastName')}`}
                    onFocus={() => handleFocus('lastName')}
                    onBlur={() => handleBlur('lastName')}
                    onChange={handleInputChange}
                    required
                    placeholder="Last Name"
                />
                <label htmlFor="lastName">Last Name</label>
                {showValidationError && fieldValidity.lastName === false && (
                    <p className="error-messages">Please enter a valid last name.</p>
                )}
            </div>
            <div className={`form-group date-picker-container ${isFocused || formData.dateOfBirth ? 'focused' : ''}`}>
                <DatePicker
                    id="dateOfBirth"
                    name="dateOfBirth"
                    value={formData.dateOfBirth ? dayjs(formData.dateOfBirth, 'DD/MM/YYYY') : null}
                    onChange={handleDateChange}
                    placeholder="Date of Birth"
                    className={`form-control custom-date-picker ${getValidityClass('dateOfBirth')}`}
                    required
                    format="DD/MM/YYYY"
                    picker="date"
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    disabledDate={disabledDate}
                />
                <label className="placeholder-label" htmlFor="dateOfBirth">Date of Birth</label>
                {showValidationError && fieldValidity.dateOfBirth === false && (
                    <p className="error-messages">Please enter a valid date of birth.</p>
                )}
            </div>
            <div className='form-group'>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    className={`form-control ${getValidityClass('email')}`}
                    onFocus={() => handleFocus('email')}
                    onBlur={() => handleBlur('email')}
                    onChange={handleInputChange}
                    required
                    placeholder="Email"
                />
                <label htmlFor="email">Email</label>
                {showValidationError && fieldValidity.email === false && (
                    <p className="error-messages">Please enter a valid email.</p>
                )}
            </div>
            <div className='form-group'>
                <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    className={`form-control ${getValidityClass('phone')}`}
                    onFocus={() => handleFocus('phone')}
                    onBlur={() => handleBlur('phone')}
                    onChange={handleInputChange}
                    required
                    placeholder="Phone"
                />
                <label htmlFor="phone">Phone</label>
                {showValidationError && fieldValidity.phone === false && (
                    <p className="error-messages">Please enter a valid Australian phone number.</p>
                )}
            </div>
        </form>
    );
};

export default PersonalForm;
