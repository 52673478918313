import React, { useEffect, useState } from "react";
import "./ProgressBar.css";

const ProgressBar = ({ progress }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (progress >= 100) {
      setTimeout(() => {
        setVisible(false);
      }, 1000); // Delay to allow user to see the full bar before it disappears
    } else {
      setVisible(true);
    }
  }, [progress]);

  return (
    visible && (
      <div className="progress-container" style={{ animation: progress >= 100 ? "fadeOut 1s forwards" : "none" }}>
        <div className="progress-bar-wrapper">
          <div className="progress-bar" style={{ width: `${progress}%` }}></div>
          <div className="stop-indicator" style={{ left: `${progress}%` }}></div>
        </div>
      </div>
    )
  );
};

export default ProgressBar;
