const getBaseURL = () => {
    // Assuming you are using this function to determine the base URL
    const hostname = window.location.hostname;
    if (hostname === "localhost") {
      return "http://localhost:4000";
    } else {
      return "https://claim.joinwell.com.au";
    }
  };
  
  export default getBaseURL;