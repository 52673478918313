import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../css/NavButtons.css";
import { ReactComponent as PrevIcon } from "../assets/images/Chevron.svg";
import { ReactComponent as NextIcon } from "../assets/images/NextIcon.svg";
import { ReactComponent as NextIconBlack } from "../assets/images/NextIconBlack.svg"; // New icon for black fill
import { FaSpinner } from "react-icons/fa";

function NavigationButtons({
  nextRoute,
  nextButtonText,
  onNextClick,
  isNextDisabled = true,
  isProcessing = false,
  processingText = "",
  isPrevDisabled,
  buttonClass = "",
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const [buttonContent, setButtonContent] = useState({
    text: nextButtonText,
    icon: nextButtonText === "Skip" ? <NextIconBlack /> : <NextIcon />,
  });

  const [shadow, setShadow] = useState(false);

  useEffect(() => {
    const checkScroll = () => {
      const fullHeight = document.body.scrollHeight;
      const viewHeight = window.innerHeight;
      const scrolled = window.scrollY;

      // Set shadow if there's more content to scroll and user isn't at the bottom
      setShadow(fullHeight > viewHeight && scrolled + viewHeight < fullHeight);
    };

    // Check on mount, on scroll, and on resize
    checkScroll();
    window.addEventListener("resize", checkScroll);
    window.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("resize", checkScroll);
      window.removeEventListener("scroll", checkScroll);
    };
  }, []);

  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (isProcessing) {
        setButtonContent({
          text: processingText,
          icon: <FaSpinner className="processing-icon" />,
        });
      } else {
        setButtonContent({
          text: nextButtonText,
          icon: nextButtonText === "Skip" ? <NextIconBlack /> : <NextIcon />,
        });
      }
    }, 50);
    return () => clearTimeout(timer);
  }, [isProcessing, processingText, nextButtonText]);

  const handleNext = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    if (onNextClick) {
      onNextClick(event, navigate);
    } else if (!isNextDisabled) {
      navigate(nextRoute);
    }
  };

  const handlePrevious = () => {
    if (!isPrevDisabled) {
      if (location.pathname === "/form") {
        // If on the form page, navigate to the upload page
        navigate("/upload");
      } else if (location.pathname === "/upload") {
        // If on the upload page, navigate to the previous page (e.g., home page)
        navigate("/middle");
      } else {
        // For other pages, go back one page
        navigate(-1);
      }
    }
  };

  return (
    <div className={`navigation-buttons ${shadow ? "shadow" : ""}`}>
      <div className="nav-buttons">
        <button className="custom-button prev-button" onClick={handlePrevious} disabled={isPrevDisabled}>
          {isDesktop ? "Back" : <PrevIcon />}
        </button>
        <button
          className={`custom-button next-button ${isProcessing ? "processing" : ""} ${buttonClass}`}
          onClick={handleNext}
          disabled={isNextDisabled || isProcessing}
        >
          {buttonContent.text}
          {buttonContent.icon}
        </button>
      </div>
    </div>
  );
}

export default NavigationButtons;
