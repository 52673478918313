import React, { useState, useCallback, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Compressor from "compressorjs";
import axios from "axios";
import ConfirmationModal from "../components/ConfirmationModal.js";
import UploadDropzone from "../components/UploadDropzone.js";
import FilePreview from "../components/FilePreview.js";
import UploadButton from "../components/UploadButton.js";
import UploadProgress from "../components/UploadProgress.js";
import UploadError from "../components/UploadError.js";
import UploadSuccess from "../components/UploadSuccess.js";
import FileUploadItem from "../components/FileUploadItem.js";
import NavButtons from "../components/NavButtons.js";
import StickyHeader from "../components/StickyHeader.js";
import { ReactComponent as UploadIcon } from "../assets/images/UploadIcon.svg";
import "../css/UploadPage.css";
import "../css/animations.css";
import getBaseURL from "../components/getBaseUrl.js";

const UploadPage = () => {
  const [file, setFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [errorFile, setErrorFile] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const fileInputRef = useRef(null);

  const isPrevDisabled = location.state?.from === 'thanks';

  const handleFileUpload = useCallback((uploadedFiles) => {
    console.log('Uploaded File:', uploadedFiles[0]);

    const file = uploadedFiles[0];
    const fileSize = file.size;

    if (!isNaN(fileSize) && fileSize !== undefined) {
      const formattedSize = `${(fileSize / 1024).toFixed(2)} KB`;
      console.log('Formatted File Size:', formattedSize);

      if (file.type.startsWith("image/")) {
        new Compressor(file, {
          quality: 0.8,
          success: (compressedResult) => {
            const compressedFile = new File([compressedResult], file.name, {
              type: compressedResult.type,
              lastModified: Date.now()
            });
            setFile({
              preview: URL.createObjectURL(compressedFile),
              compressedFile,
              name: file.name,
              size: formattedSize,
              status: "uploading",
              progress: 0,
              error: null,
              isInvalid: false,
            });
            setErrorFile(null);
          },
          error: (error) => {
            console.error('Compression Error:', error);
            setErrorFile({ fileName: file.name, errorMessage: "Error processing file." });
          },
        });
      } else {
        setFile({
          preview: URL.createObjectURL(file),
          compressedFile: file,
          name: file.name,
          size: formattedSize,
          status: "uploading",
          progress: 0,
          error: null,
          isInvalid: false,
        });
        setErrorFile(null);
      }
    } else {
      console.error('File size is not accessible or invalid:', file);
      setErrorFile({ fileName: file.name, errorMessage: "Invalid file size" });
    }
  }, []);

  const handleRemoveFile = () => {
    setIsConfirmationOpen(true);
  };

  const handleConfirmRemove = () => {
    setFile(null);
    sessionStorage.removeItem("fileDetails");
    setIsConfirmationOpen(false);
  };

  const handleCancelRemove = () => {
    setIsConfirmationOpen(false);
  };

  const handleNext = async () => {
    if (!file) {
      setErrorFile({ fileName: null, errorMessage: "Please select a file to upload." });
      return;
    }

    setIsProcessing(true);

    try {
      const formData = new FormData();
      formData.append("files", file.compressedFile);

      const response = await axios.post(`${getBaseURL()}/upload2`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const { successResponses, message } = response.data;

      if (successResponses.length > 0) {
        const updatedFile = {
          ...file,
          s3Key: successResponses[0].s3Key,
          s3Url: successResponses[0].s3Url,
          status: "uploaded"
        };

        setFile(updatedFile);
        console.log('Updated File:', updatedFile); // Log the updated file
        sessionStorage.setItem("fileDetails", JSON.stringify(updatedFile));
        navigate("/form", { state: { uploadedData: [updatedFile] } });
      } else {
        setErrorFile({ fileName: null, errorMessage: message });
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setErrorFile({ fileName: null, errorMessage: "Error uploading file. Please try again." });
    } finally {
      setIsProcessing(false);
    }
  };

  const handleFileSelect = (event) => {
    const selectedFiles = Array.from(event.target.files);
    handleFileUpload(selectedFiles);
  };

  useEffect(() => {
    return () => file && URL.revokeObjectURL(file.preview);
  }, [file]);

  return (
    <div className="main-content">
      <div className="container">
        <StickyHeader currentStep={1} />
        <div className="content-container upload">
          <UploadIcon />
          {errorFile && (
            <div className="error-message1">
              <p>{errorFile.errorMessage}</p>
            </div>
          )}
          <h1>Upload a Receipt</h1>
          <p>
            Upload your receipt, tell us a bit about you and we’ll take the rest from there.
          </p>
          <UploadDropzone
            onFileUpload={handleFileUpload}
            fileInputRef={fileInputRef}
            files={file ? [file] : []}
            tooltipText={[
              {
                type: "list",
                heading: "Accepted file types:",
                items: [
                  "PDF",
                  "JPEG",
                  "PNG"
                ]
              },
              {
                type: "list",
                heading: "Maximum file size:",
                items: [
                  "1.5MB"
                ]
              }
            ]}
            tooltipClassName="small" // Pass the class name for large font size
            allowMultiple={false} // Only allow one file at a time
          />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileSelect}
          />
          <div className="upload-section">
            <div className="preview-section">
              {file && (
                <FileUploadItem
                  file={file}
                  onRemove={handleRemoveFile}
                  isInvalid={errorFile?.fileName === file.name}
                />
              )}
            </div>
          </div>
          <ConfirmationModal
            open={isConfirmationOpen}
            onConfirm={handleConfirmRemove}
            onCancel={handleCancelRemove}
          />
        </div>
        <NavButtons
          nextButtonText="Continue"
          onNextClick={handleNext}
          isNextDisabled={!file}
          isProcessing={isProcessing}
          processingText="Processing..."
          isPrevDisabled={isPrevDisabled}
        />
      </div>
    </div>
  );
};

export default UploadPage;
